<template>
    <setting-layout :title="$t('pages.user.setting.email.title')">
        <v-container>
            <v-row class="ma-0 pt-3 pb-3" v-for="(item, index) in inputs" :key="index">
                <text-field :placeholder="item.field"
                            v-model="item.model"
                            v-on:keyup.enter="save"
                            :error-messages="getErrors('email')">

                </text-field>
            </v-row>
            <v-row justify="center" class="pa-10">
                <gradient-button block @click="save"
                                 :loading="$store.getters.loadingBtn(['setting', 'emailChangeBtn'])">{{ $t('pages.user.setting.email.update') }}
                </gradient-button>
            </v-row>
        </v-container>
    </setting-layout>
</template>

<script>
import TextField from "@/components/app/form/TextField";
import GradientButton from "@/components/app/button/GradientButton";
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import {mapActions} from "vuex";

export default {
    name: "Email",
    data: () => ({
        errors: {},
    }),
    computed: {
        inputs() {
            return [
                {field: this.$t('pages.user.setting.email.email'), model: this.$store.state.user.user.data.email || this.$t('pages.user.setting.email.example')},
            ]
        },
    },
    methods: {
        save() {
            const buildData = {email: this.inputs[0].model}
            this.errors = {}
            const user = this.$store.state.user.user
            this.sendEmailChange({model: buildData})
                .then(async () => {
                    this.$store.commit('showSnackBar', this.$t('messages.success'))
                    user.email = buildData.email
                    await this.getOwnMainUserData(user)
                    await this.$router.push({'name': 'email-verify'})
                })
                .catch(error => {
                    this.errors = error.response.data
                })
        },
        getErrors(id) {
            if (typeof this.errors.errors === 'object') {
                if (typeof this.errors.errors[id] !== 'undefined')
                    return this.errors.errors[id][0];
            } else {
                return ""
            }
        },
        ...mapActions(['sendEmailChange', 'getOwnMainUserData'])
    },
    mounted() {
        if (typeof this.$store.state.user.user.data.email === 'undefined') {
            this.getOwnMainUserData(this.$auth.user())
        }
        this.inputs[0].model = this.$store.state.user.user.data.email
    },
    components: {SettingLayout, TextField, GradientButton}
}
</script>

<style scoped>

</style>
